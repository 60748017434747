const support = {
    getDate: (dbDate) => {
        let t = dbDate.split(/[- :]/);
// console.log(t);
// Apply each element to the Date function
        let d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
         // console.log("datum je: " + d.toLocaleDateString('cs-CZ') + " " + d.toLocaleString('cs-CZ'));
         // console.log("datum je: " + d.valueOf());
        // var actiondate = new Date(d);
        return d;
    },

    getShortDate: (dbDate) => {
        let t = dbDate.split(/[- :]/);
// console.log(t);
// Apply each element to the Date function
        let d = new Date(t[0], t[1] - 1, t[2]);
         // console.log("datum je: " + d.toLocaleDateString('cs-CZ') + " " + d.toLocaleString('cs-CZ'));
        // console.log("datum je: " + d.valueOf());
        // var actiondate = new Date(d);
        return d;
    },

    monthBack: () => {
        let date = new Date();
        date.setMonth(date.getMonth() - 1);
        date.setHours(0, 0, 0);
        return date;
    },

    twoWeeksBack: () => {
        let date = new Date();
        date.setDate(date.getDate() - 14);
        // date.setHours(0, 0, 0);
        return date;

    },


    arrayComp: (arr, obj) => {
        console.log("New Array Compare");
        let a = JSON.stringify(arr);
        let b = JSON.stringify(obj);
        let result = a === b;
        console.log("Vysledek porovnani " + result);
        return result;
    },


    replaceNextLine: (text) => {
        // console.log("Text replacement "+text);
        return text.replaceAll("\\n", "<br>")
    },

    calcPrice:(basePrice, totalItems) => {
    let finalPrice = 0;

    for (let i = 1; i <= totalItems; i++) {
        let discount = 0;

        if (i <= 7) {
            discount = 0.10; // 10% discount
        } else if (i <= 12) {
            discount = 0.13; // 13% discount
        } else {
            discount = 0.15; // 15% discount
        }

        const itemPrice = basePrice - (basePrice * discount);
        finalPrice += itemPrice;
    }

    return finalPrice;
}

}

export default support;